import React from "react";
import Helmet from "react-helmet";
import { Content } from "components/Content";
import { TextBlock } from "components/TextBlock";
import { List } from "components/List";
import { Hero } from "components/Hero";
import { images } from "components/HeroImages";
import TicketShop from "components/TicketShop";
import { ticketShopScript } from "constants/ticketShopScript";

function EventsPage(p) {
  const title = "Events | Status100 Promotions";
  const description = "Status100 Promotions events.";

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={title}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:url`,
            content: p.location.href,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:image`,
            content: ``,
          },
          {
            name: "facebook-domain-verification",
            content: ``,
          },
        ]}
      />
      <Hero image={images.events}>
        <h1>Events</h1>
      </Hero>
      <Content>
        <TicketShop scriptString={ticketShopScript} />
      </Content>
    </React.Fragment>
  );
}

export default EventsPage;
