import React from "react";

import { StaticImage } from "gatsby-plugin-image";

const homeSrc = "../images/heroHome.png";
const homeStaticSrc = "/heroHome.png";
export function HeroHome() {
  return (
    <StaticImage
      src={homeSrc}
      alt=""
      layout="fullWidth"
      objectFit="cover"
      objectPosition="center"
    />
  );
}
HeroHome.src = homeStaticSrc;

const eventsSrc = "../images/heroEvents.png";
const eventsStaticSrc = "/heroEvents.png";
export function HeroEvents() {
  return (
    <StaticImage
      src={eventsSrc}
      alt=""
      layout="fullWidth"
      objectFit="cover"
      objectPosition="center"
    />
  );
}
HeroEvents.src = eventsStaticSrc;

const gallerySrc = "../images/heroGallery.png";
const galleryStaticSrc = "/heroGallery.png";
export function HeroGallery() {
  return (
    <StaticImage
      src={gallerySrc}
      alt=""
      layout="fullWidth"
      objectFit="cover"
      objectPosition="center"
    />
  );
}
HeroGallery.src = galleryStaticSrc;

const contactSrc = "../images/heroContact.png";
const contactStaticSrc = "/heroContact.png";
export function HeroContact() {
  return (
    <StaticImage
      src={contactSrc}
      alt=""
      layout="fullWidth"
      objectFit="cover"
      objectPosition="center"
    />
  );
}
HeroContact.src = contactStaticSrc;

const joinSrc = "../images/heroJoin.png";
const joinStaticSrc = "/heroJoin.png";
export function HeroJoin() {
  return (
    <StaticImage
      src={joinSrc}
      alt=""
      layout="fullWidth"
      objectFit="cover"
      objectPosition="center"
    />
  );
}
HeroJoin.src = joinStaticSrc;

export const images = {
  home: {
    node: <HeroHome />,
    src: HeroHome.src,
  },
  events: {
    node: <HeroEvents />,
    src: HeroEvents.src,
  },
  gallery: {
    node: <HeroGallery />,
    src: HeroGallery.src,
  },
  contact: {
    node: <HeroContact />,
    src: HeroContact.src,
  },
  join: {
    node: <HeroJoin />,
    src: HeroJoin.src,
  },
  default: {
    node: <HeroHome />,
    src: HeroHome.src,
  },
};
